import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Seo from '../components/seo'
import Helmet from 'react-helmet'

const Main = styled.main`
  background-color: #788463;
  min-height: 100vh;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: 1fr;
  margin-left: 40px;
  margin-right: 40px;
  grid-row-gap: 0px;
  grid-column-gap: 20px;
  min-height: calc(100vh - 100px);
  @media(min-width: 2000px) {
    grid-template-columns: repeat(24, 1fr);
  }
  @media (max-width: 1440px) {
    grid-template-columns: repeat(14, 1fr);
  }
  @media (max-width: 450px) {
    margin-left: 20px;
    margin-right: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
`;

const Inner = styled.div`
  grid-area: 1 / 5 / 2 / 11;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media(min-width: 2000px) {
    grid-area: 1 / 9 / 2 / 17;
  }
  @media (max-width: 1440px) {
    grid-area: 1 / 5 / 2 / 11;
  }
  @media (max-width: 450px) {
    grid-area: 1 / 1 / 2 / 2;
  }
`;

const Contact = styled.div`
  display: flex;
  flex-shrink: 1;
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
    .col:first-of-type {
      margin-bottom: 30px;
    }
  }
  a {
    color: #000;
    text-decoration: none;
  }
`;

const Footer = styled.div`
font-family: "MessinaSans", Arial, Helvetica, sans-serif;
opacity: 0.2;
font-size: 14px;
line-height: 22px;
@media (max-width: 850px) {
  font-size: 12px;
  line-height: 19px;
}
@media(min-width: 2000px) {
      font-size: 17px;
      line-height: 26px;
}
a {
  text-decoration: none;
  color: #000;
  border-bottom: 1px solid transparent;
  transition: all 0.25s ease-in-out;
  &:hover {
    border-bottom: 1px solid #000;
  }
}
`

const Col = styled.div`
  width: 100%;
  p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;
    @media(min-width: 2000px) {
        font-size: 17px;
        line-height: 26px;
    }
    @media (max-width: 850px) {
      font-size: 12px;
      line-height: 19px;
    }
  }
`;

const Row = styled.div`
  margin-bottom: 55px;
  p {
   font-size: 14px;
   line-height: 22px;
   letter-spacing: 0.05em;
   @media(min-width: 2000px) {
      font-size: 17px;
      line-height: 26px;
    }
    @media (max-width: 850px) {
      font-size: 12px;
      line-height: 19px;
    }
  }
`;

const Home = () => {
  const id = "-f593c083-8d6d-559c-8587-0042da70f7a9";

  const data = useStaticQuery(graphql`
    query {
      allSanityAbout {
        edges {
          node {
            instagram
            phone
            title
            body
            email
            id
          }
        }
      }
    }
  `);

  const about_data = data.allSanityAbout.edges.filter(
    (d) => d.node.id === id
  )[0].node;

  const { body, email, instagram, phone } = about_data;

  const phone_link =
    "tel:" + phone.replace(" ", "").replace("(0)", "").replace(/\s+/g, "");

  const mail = email.replace(/\s+/g, "");
  const email_link = "mailto:" + mail;

  return (
    <>
      <Seo title="About" slug="/about" />
      <Helmet bodyAttributes={{ class: 'light' }} />
      <Main>
        <Grid>
          <Inner>
            <div></div>
            <div>
              <Row>
                <p>{body}</p>
              </Row>
              <Contact className="contact">
                <Col className="col">
                  <p>
                    Contact
                    <br />
                    <a href={email_link}>{mail}</a>
                    <br />
                    <a href={phone_link}>{phone}</a>
                  </p>
                </Col>
                <Col className="col">
                  <p>
                    Follow
                    <br />
                    <a href={`https://instagram.com/${instagram}`}>@{instagram}</a>
                  </p>
                </Col>
              </Contact>
            </div>
            <Footer>Brand identity and web design by <a href="http://www.salina.design/">Salina</a>.</Footer>
          </Inner>
        </Grid>
      </Main>
    </>
  );
};

export default Home;
